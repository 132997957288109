import {InputAdornment, MenuItem, Stack, TextField} from '@mui/material';
import Iconify from "src/components/Iconify";
// components

// ----------------------------------------------------------------------


type Props = {
    filterName: string;
    onFilterName: (value: string) => void;
    courses: Array<{ id: string, name: string }>
    selectedCourse: string
    onSelectCourse: (value: string) => void;
};

const StudentTableToolbar = ({
                                 filterName,
                                 onFilterName,
                                 courses,
                                 selectedCourse,
                                 onSelectCourse
                             }: Props) => (
    <Stack spacing={2} direction={{xs: 'column', md: 'row'}} sx={{py: 2.5, px: 3}}>
        <TextField
            fullWidth
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder="Cauta dupa nume sau email..."
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Iconify
                            icon={'eva:search-fill'}
                            sx={{color: 'text.disabled', width: 20, height: 20}}
                        />
                    </InputAdornment>
                ),
            }}
        />
        <TextField
            fullWidth
            select
            label="Cursuri"
            value={selectedCourse}
            onChange={(e) => onSelectCourse(e.target.value)}
            SelectProps={{
                MenuProps: {
                    sx: {'& .MuiPaper-root': {maxHeight: 300}},
                },
            }}
            sx={{
                maxWidth: {md: 350},
                textTransform: 'capitalize',
            }}
        >
            {courses.map((course) => (
                <MenuItem
                    key={course.id}
                    value={course.id}
                    sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                    }}
                >
                    {course.name}
                </MenuItem>
            ))}
        </TextField>
    </Stack>
);
export default StudentTableToolbar
