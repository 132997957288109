import {configureStore} from '@reduxjs/toolkit'
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from "src/store/authSlice";
import {combineReducers} from 'redux';

import {setupListeners} from "@reduxjs/toolkit/query";
import {studentApiService} from "src/services/studentApiService";
import {rtkQueryErrorMiddleware} from "src/store/rtkQueryErrorMiddleware";
import {authApiService} from "src/services/authApiService";
import {courseApiService} from "src/services/courseApiService";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: [authApiService.reducerPath, studentApiService.reducerPath, courseApiService.reducerPath]
}

const rootReducer = combineReducers({
    auth: authReducer,
    [authApiService.reducerPath]: authApiService.reducer,
    [studentApiService.reducerPath]: studentApiService.reducer,
    [courseApiService.reducerPath]: courseApiService.reducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    //@ts-ignore
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(studentApiService.middleware, authApiService.middleware, courseApiService.middleware, rtkQueryErrorMiddleware)
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch