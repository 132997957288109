import {baseApiService} from "src/services/baseApiService";
import {PageType} from "src/domain/pageable/PageType";
import {PageableType} from "src/domain/pageable/PageableType";
import {StudentEnrolmentType} from "src/domain/StudentEnrolmentType";
import {StudentType} from "src/domain/StudentType";

export const studentApiService = baseApiService.injectEndpoints({
    endpoints: (build) => ({
        findAllStudents: build.query<PageType<StudentType>, PageableType & { searchTerm: string, selectedCourseId: string, paid: boolean }>({
            query: (query) => ({
                url: `/student`,
                params: {...query}
            }),
            providesTags: ['Students']
        }),
        getStudentEnrolments: build.query<PageType<StudentEnrolmentType>, PageableType & { searchTerm: string, courseId: string | undefined, progressMin: number, progressMax: number, excludeFreeCourse: boolean, onlyExpired: boolean }>({
            query: (query) => ({
                url: `/student/enrolments`,
                params: {...query}
            }),
            providesTags: ['Enrolments']
        }),
        getCourses: build.query<Array<{ id: string, name: string }>, void>({
            query: () => ({
                url: `/student/courses`
            })
        }),
        getStats: build.query<{ students: number, users: string }, void>({
            query: () => ({
                url: `/student/stats`
            })
        })
    })
})

export const {
    useFindAllStudentsQuery, useGetCoursesQuery, useGetStatsQuery, useGetStudentEnrolmentsQuery
} = studentApiService;