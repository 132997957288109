import {baseApiService} from "src/services/baseApiService";
import {CourseType} from "src/domain/CourseType";

export const courseApiService = baseApiService.injectEndpoints({
    endpoints: (build) => ({
        getAllCourses: build.query<Array<CourseType>, void>({
            query: () => ({
                url: `/courses`
            }),
            providesTags: ['Courses']
        })
    })
})

export const {
    useGetAllCoursesQuery
} = courseApiService;