import {
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import React from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import LogoutButton from "src/components/LogoutButton";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import {AiOutlineDashboard} from 'react-icons/ai';
import {IoLibrarySharp, IoPeople} from 'react-icons/io5';

const drawerWidth = 240;

const DefaultLayout = () => {
    const location = useLocation()
    const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box>
            <List sx={{marginTop: '50px'}}>
                <ListItemButton sx={{px: 2}} component={Link} to="/" selected={'/' === location.pathname}>
                    <ListItemIcon>
                        <AiOutlineDashboard size={25}/>
                    </ListItemIcon>
                    <ListItemText primary={'Dashboard'}/>
                </ListItemButton>
                <Divider light/>
                <ListItemButton sx={{px: 2}}
                                component={Link}
                                to="/students"
                                selected={'/students' === location.pathname}>
                    <ListItemIcon>
                        <IoPeople size={25}/>
                    </ListItemIcon>
                    <ListItemText primary={'Studenti'}/>
                </ListItemButton>
                <Divider light/>
                <ListItemButton sx={{px: 2}}
                                component={Link}
                                to="/courses"
                                selected={'/courses' === location.pathname}>
                    <ListItemIcon>
                        <IoLibrarySharp size={25}/>
                    </ListItemIcon>
                    <ListItemText primary={'Cursuri'}/>
                </ListItemButton>
            </List>
        </Box>
    );
    return (
        <Box sx={{display: 'flex', flexGrow: 1}}>
            <CssBaseline/>
            <AppBar position="fixed" sx={{mb: 4, zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar variant={"dense"}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {lg: 'none'}}}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Germana cu Inesa
                    </Typography>
                    <Typography variant="h6" component="div">
                        Analytics
                    </Typography>
                    <LogoutButton/>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {lg: drawerWidth}, flexShrink: {md: 0}}}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    sx={{
                        display: {md: 'block', lg: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', lg: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                    PaperProps={{
                        sx: {
                            borderRightStyle: 'dashed',
                            bgcolor: 'background.default',
                            transition: (theme) =>
                                theme.transitions.create('width', {
                                    duration: theme.transitions.duration.standard,
                                })
                        }
                    }}>
                    {drawer}
                </Drawer>
            </Box>
            <Outlet/>
        </Box>
    );
};

export default DefaultLayout;