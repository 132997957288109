import React from "react";
import {Theme} from '@mui/material/styles';
import {Box, SxProps, TableCell, TableHead, TableRow, TableSortLabel,} from '@mui/material';

// ----------------------------------------------------------------------

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

type Props = {
    order?: 'asc' | 'desc';
    orderBy?: string;
    headLabel: any[];
    rowCount?: number;
    numSelected?: number;
    onSort?: (id: string) => void;
    onSelectAllRows?: (checked: boolean) => void;
    sx?: SxProps<Theme>;
};

const TableHeadCustom = ({
                             order,
                             orderBy,
                             headLabel,
                             onSort,
                             sx
                         }: Props) => (
    <TableHead sx={sx}>
        <TableRow>
            {headLabel.map((headCell) => (
                <TableCell
                    key={headCell.id}
                    align={headCell.align || 'left'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{width: headCell.width, minWidth: headCell.minWidth}}
                >
                    {onSort && headCell.sortable ? (
                        <TableSortLabel
                            // hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => onSort(headCell.id)}
                            sx={{textTransform: 'capitalize'}}
                        >
                            {headCell.label}

                            {orderBy === headCell.id ? (
                                <Box sx={{...visuallyHidden}}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    ) : (
                        headCell.label
                    )}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);
export default TableHeadCustom
