import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {persistor, store} from "src/store/store";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import 'moment/locale/ro'
import moment from "moment/moment";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThemeProvider from "src/theme";
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {createRoot} from "react-dom/client";

moment.locale('ro')
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider>
                        <BrowserRouter>
                            <App/>
                            <ToastContainer/>
                        </BrowserRouter>
                    </ThemeProvider>
                </LocalizationProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
