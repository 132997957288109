import React, {useMemo} from 'react';
import {
    Avatar,
    Box,
    Collapse,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from '@mui/material';
import {StudentType} from "src/domain/StudentType";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from "moment";
import Iconify from "src/components/Iconify";
import useTabs from 'src/hooks/useTabs';
import {TabContext, TabPanel} from "@mui/lab";
import createAvatar from "src/utils/createAvatar";

type Props = {
    row: StudentType;
};

const StudentTableRow = ({
                             row,
                         }: Props) => {
    const [open, setOpen] = React.useState(false);
    const {currentTab, onChangeTab} = useTabs('courses');

    const {fullName, email, amountSpent, createdAt, lastSignIn} = row;

    return useMemo(() =>
        <>
            <TableRow hover>
                <TableCell align={"center"}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell sx={{display: 'flex', alignItems: 'center'}}>
                    <Avatar alt={fullName} color={createAvatar(fullName).color} sx={{mr: 2}}>
                        {createAvatar(fullName).name}
                    </Avatar>
                    <Typography variant="subtitle2" noWrap>
                        {fullName}
                    </Typography>
                </TableCell>

                <TableCell align="left">{email}</TableCell>

                <TableCell align="left">
                    {`${moment(createdAt).format('D MMM YYYY')}`}
                </TableCell>
                <TableCell align="left">
                    {`${moment(lastSignIn).format('D MMM YYYY')}`}
                </TableCell>

                <TableCell align="center">
                    {`${amountSpent}`}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <TabContext value={currentTab}>
                                <Tabs
                                    allowScrollButtonsMobile
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    value={currentTab}
                                    onChange={onChangeTab}
                                    sx={{padding: 1}}
                                >
                                    {[
                                        {
                                            label: "Inrolari",
                                            value: 'courses',
                                            icon: <Iconify icon={'ic:round-receipt'} width={20} height={20}/>
                                        },
                                        {
                                            label: "Facturi",
                                            value: 'orders',
                                            icon: <Iconify icon={'ic:round-receipt'} width={20} height={20}/>
                                        }
                                    ].map((tab) => (
                                        <Tab
                                            disableRipple
                                            key={tab.value}
                                            value={tab.value}
                                            icon={tab.icon}
                                            label={tab.label}
                                        />
                                    ))}
                                </Tabs>
                                <TabPanel value={'courses'}>
                                    <Table size="small" aria-label="courses" style={{marginBottom: 2}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nume curs</TableCell>
                                                <TableCell>Inrolat</TableCell>
                                                <TableCell>Cumparat</TableCell>
                                                <TableCell>Completat</TableCell>
                                                <TableCell>Expira</TableCell>
                                                <TableCell>Progres</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                row.enrollments.map(({
                                                                         enrollmentCourse,
                                                                         enrolledAt,
                                                                         activatedAt,
                                                                         completedAt,
                                                                         expiryDate,
                                                                         progress
                                                                     }, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {enrollmentCourse?.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {`${enrolledAt ? moment(enrolledAt).format('D MMM YYYY') : ''}`}
                                                        </TableCell>
                                                        <TableCell>
                                                            {`${activatedAt ? moment(activatedAt).format('D MMM YYYY') : ''}`}
                                                        </TableCell>
                                                        <TableCell>
                                                            {`${completedAt ? moment(completedAt).format('D MMM YYYY') : ''}`}
                                                        </TableCell>
                                                        <TableCell>
                                                            {`${expiryDate ? moment(expiryDate).format('D MMM YYYY') : ''}`}
                                                        </TableCell>
                                                        <TableCell>
                                                            {progress}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TabPanel>
                                <TabPanel value={'orders'}>
                                    <Table size="small" aria-label="purchases" style={{marginBottom: 2}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data</TableCell>
                                                <TableCell>Curs</TableCell>
                                                <TableCell>Suma</TableCell>
                                                <TableCell>Metoda de plata</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                row.orders.map(order => (
                                                    <TableRow>
                                                        <TableCell>{`${order.orderDate ? moment(order.orderDate).format('D MMM YYYY') : ''}`}</TableCell>
                                                        <TableCell>{order.orderProductName}</TableCell>
                                                        <TableCell>{order.displayAmount}</TableCell>
                                                        <TableCell>{order.paymentMethod}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>, [row, open, currentTab]);
};

export default StudentTableRow;
