import React, {useMemo} from "react";
import {Slider} from "@mui/material";
import {debounce} from "lodash";


const valuetext = (value: number) => `${value}%`;

interface ProgressSliderProps {
    onValueChange: (value: number[]) => void
}

const ProgressSlider = ({onValueChange}: ProgressSliderProps) => {
    const handleChange = (event: Event, newValue: number | number[]) => {
        onValueChange(newValue as number[]);
    };
    const delayedOnChangeValue = useMemo(() => debounce(handleChange, 300), [handleChange]);

    return (
        <Slider
            getAriaLabel={() => 'Progress range'}
            defaultValue={[0, 100]}
            onChange={delayedOnChangeValue}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
        />
    );
}

export default ProgressSlider;