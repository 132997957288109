import {ReactNode, useMemo} from 'react';
// @mui
import {CssBaseline} from '@mui/material';
import {createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider} from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, {customShadows} from './shadows';

// ----------------------------------------------------------------------

type Props = {
    children: ReactNode;
};

const ThemeProvider = ({children}: Props) => {

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette: palette.light,
            typography,
            breakpoints,
            shape: {borderRadius: 8},
            shadows: shadows.light,
            customShadows: customShadows.light
        }),
        []
    );

    const theme = createTheme(themeOptions);

    theme.components = componentsOverride(theme);

    return (
        <MUIThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </MUIThemeProvider>
    );
};
export default ThemeProvider
