import {
    Avatar,
    Card,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    LinearProgress,
    MenuItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Page from "src/components/Page";
import {useGetStudentEnrolmentsQuery} from "src/services/studentApiService";
import React, {useMemo, useState} from "react";
import {TableHeadCustom} from "src/components/table";
import {StudentEnrolmentType} from "src/domain/StudentEnrolmentType";
import CircularProgressWithLabel from "src/components/CircularProgressWithLabel";
import moment from "moment/moment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Iconify from "src/components/Iconify";
import {useGetAllCoursesQuery} from "src/services/courseApiService";
import {debounce, filter, find, sortBy} from "lodash";
import PaymentsIcon from '@mui/icons-material/Payments';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import IconButton from '@mui/material/IconButton';
import {EnrolmentFiltersType} from "src/domain/EnrolmentFiltersType";
import {CourseType} from "src/domain/CourseType";
import ProgressSlider from "src/components/ProgressSlider";
import {alpha} from "@mui/material/styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const TABLE_HEAD = [
    {sortable: false},
    {id: 'courseName', label: 'Curs', align: 'left', sortable: true},
    {id: 'fullName', label: 'Student', align: 'left', sortable: true},
    {id: 'enrolledAt', label: 'Inrolat', align: 'left', sortable: true},
    {id: 'progress', label: 'Progress', align: 'left', sortable: true},
];

const getProgressColor = (row: StudentEnrolmentType): 'success' | 'warning' | 'primary' | 'info' | 'secondary' | 'error' => {
    if (!!row.completedAt) {
        return "success"
    } else if (!!row.expiryDate && moment(row.expiryDate).isSameOrBefore(moment())) {
        return "error";
    } else if (!!row.expiryDate && moment(row.expiryDate).isSameOrAfter()) {
        return "warning";
    } else if (!!row.activatedAt) {
        return "info";
    } else {
        return "secondary";
    }
}

const EnrolmentTableRow = ({row, course}: { row: StudentEnrolmentType, course: CourseType | undefined }) => {

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>
                <Avatar alt={course?.name}
                        sx={{width: 50, height: 40, borderRadius: 1}}
                        variant="square"
                        src={course?.cardImageUrl}/>
            </TableCell>
            <TableCell>
                <Typography variant={"body2"}>{row.course.name}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant={"body1"} sx={{pl: 1}}>
                    {row.fullName}
                </Typography>
                <Stack direction={"row"}
                       sx={{backgroundColor: (theme) => alpha(theme.palette.grey[300], 0.4), borderRadius: 1}}
                       justifyContent={"space-between"} spacing={2} alignItems={"center"}>
                    <Typography variant={"body2"} component={"span"} sx={{pl: 1}}>
                        {row.email}
                    </Typography>
                    <Tooltip title="Copy email">
                        <IconButton>
                            <CopyToClipboard text={row.email}>
                                <ContentCopyIcon fontSize={"small"}/>
                            </CopyToClipboard>
                        </IconButton>
                    </Tooltip>
                </Stack>

            </TableCell>
            <TableCell>
                {!!row.enrolledAt &&
                    <Chip label={moment(row.enrolledAt).format('D MMM YYYY')}
                          size="small"
                          sx={{ml: 1}}
                          icon={<Tooltip title="Inrolat in curs"><MenuBookIcon sx={{p: .2}}/></Tooltip>}
                          variant="outlined"/>}
                {!!row.activatedAt &&
                    <Chip label={moment(row.activatedAt).format('D MMM YYYY')}
                          size="small"
                          sx={{ml: 1}}
                          icon={<Tooltip title="Cumparat"><PaymentsIcon sx={{p: .2}}/></Tooltip>}
                          variant="outlined"/>}
                {!!row.expiryDate &&
                    <Chip label={moment(row.expiryDate).format('D MMM YYYY')}
                          size="small"
                          sx={{ml: 1}}
                          icon={<Tooltip title="Expira"><QueryBuilderIcon sx={{p: .2}}/></Tooltip>}
                          color={moment(row.expiryDate).isSameOrBefore(moment()) && row.progress < 100 ? 'error' : 'primary'}
                          variant="outlined"/>}
                {!!row.completedAt &&
                    <Chip label={moment(row.completedAt).format('D MMM YYYY')}
                          size="small"
                          color={"primary"}
                          icon={<Tooltip title="Completat"><CheckCircleIcon sx={{p: .2}}/></Tooltip>}
                          sx={{ml: 1}}
                          variant="outlined"/>}
            </TableCell>
            <TableCell>
                <CircularProgressWithLabel value={row.progress} color={getProgressColor(row)}/>
            </TableCell>
        </TableRow>
    );
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const defaultFilters: EnrolmentFiltersType = {
    rowsPerPage: 25,
    page: 0,
    orderBy: 'activatedAt',
    order: 'desc',
    selectedCourse: 'all',
    searchTerm: '',
    progressMin: 0,
    progressMax: 100,
    excludeFreeCourse: true,
    onlyExpired: false
};
const DashboardPage = () => {


    const [filters, setFilters] = useState<EnrolmentFiltersType>(defaultFilters);

    const {data: courses} = useGetAllCoursesQuery();

    const {data: enrolments, isFetching} = useGetStudentEnrolmentsQuery({
        page: filters.page,
        size: filters.rowsPerPage,
        sort: `${filters.orderBy},${filters.order}`,
        courseId: filters.selectedCourse !== 'all' ? filters.selectedCourse : undefined,
        searchTerm: filters.searchTerm,
        progressMin: filters.progressMin,
        progressMax: filters.progressMax,
        excludeFreeCourse: filters.excludeFreeCourse,
        onlyExpired: filters.onlyExpired
    });

    const onInputChange = (searchTerm: string) => {
        setFilters(prevState => ({...prevState, searchTerm, page: 0}))
    }

    const searchDelayed = useMemo(
        () => debounce(onInputChange, 300),
        [onInputChange]
    );

    return (
        <Page title={'Inrolari'} sx={{display: 'flex', flexGrow: 1, mt: 10}}>
            <Card sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', m: 1}}>
                <Stack spacing={2} direction={{xs: 'column', md: 'row'}} sx={{py: 1, px: 3}}>
                    <TextField
                        sx={{maxWidth: 300}}
                        fullWidth
                        size={"small"}
                        onChange={(event) => searchDelayed(event.target.value)}
                        placeholder="Cauta dupa nume sau email..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify
                                        icon={'eva:search-fill'}
                                        sx={{color: 'text.disabled', width: 20, height: 20}}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Iconify
                                        icon={'noto:man-student-light-skin-tone'}
                                        sx={{color: 'text.disabled', width: 20, height: 20}}
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                    {
                        useMemo(() => (
                            <TextField
                                fullWidth
                                select
                                label="Cursuri"
                                size={"small"}
                                onChange={(event) => setFilters(prevState => ({
                                    ...prevState,
                                    selectedCourse: event.target.value,
                                    page: 0
                                }))}
                                value={filters.selectedCourse}
                                SelectProps={{
                                    MenuProps: {
                                        sx: {'& .MuiPaper-root': {maxHeight: 300}},
                                    },
                                }}
                                sx={{
                                    maxWidth: {md: 350},
                                    textTransform: 'capitalize',
                                }}
                            >
                                {sortBy(filter([{
                                    id: 'all',
                                    name: 'Toate cursurile',
                                    isPublished: true,
                                    position: -1
                                }, ...(courses || [])], 'isPublished'), 'position').map((course) => (
                                    <MenuItem
                                        key={course.id}
                                        value={course.id}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {course.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        ), [courses, filters.selectedCourse])
                    }
                </Stack>
                <Stack spacing={2} direction={{xs: 'column', md: 'row'}} sx={{py: 1, px: 3}}>
                    <Grid container spacing={2}>
                        <Grid item md={2} sm={3} xs={4}>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Chip label={filters.progressMin} size={"small"}/>
                                <ProgressSlider onValueChange={(rangeValue: number[]) => {
                                    setFilters(prevState => ({
                                        ...prevState,
                                        page: 0,
                                        progressMin: rangeValue[0],
                                        progressMax: rangeValue[1]
                                    }))
                                }}/>
                                <Chip label={filters.progressMax} size={"small"}/>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <FormGroup row>
                                <FormControlLabel control={
                                    <Checkbox checked={filters.excludeFreeCourse}
                                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilters(prevState => ({
                                                  ...prevState,
                                                  page: 0,
                                                  excludeFreeCourse: event.target.checked
                                              }))}
                                    />}
                                                  label="Exclude Curs Gratuit"/>
                                <FormControlLabel control={
                                    <Checkbox checked={filters.onlyExpired}
                                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilters(prevState => ({
                                                  ...prevState,
                                                  page: 0,
                                                  onlyExpired: event.target.checked
                                              }))}
                                    />}
                                                  label="Doar expirate"/>
                                {/*<IconButton aria-label="delete" onClick={() => setFilters(defaultFilters)}>*/}
                                {/*    <DeleteSweepIcon color={"error"}/>*/}
                                {/*</IconButton>*/}
                            </FormGroup>
                        </Grid>
                    </Grid>

                </Stack>
                {isFetching && (<LinearProgress/>)}
                <TableContainer component={Paper} sx={{maxHeight: 500}}>
                    <Table sx={{minWidth: 600}} size={"small"} stickyHeader>
                        <TableHeadCustom
                            order={filters.order}
                            orderBy={filters.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={enrolments?.totalElements || 0}
                            onSort={(orderBy) => setFilters((prevState) => ({
                                ...prevState,
                                orderBy,
                                order: prevState.order === 'asc' ? 'desc' : 'asc'
                            }))}
                        />
                        {
                            useMemo(() => (
                                <TableBody>
                                    {
                                        enrolments?.content.map((row: StudentEnrolmentType) => (
                                            <EnrolmentTableRow row={row} key={row.id}
                                                               course={find(courses, (course) => course.id === row.course.id)}/>
                                        ))

                                    }
                                </TableBody>
                            ), [enrolments])
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={enrolments?.totalElements || 0}
                    rowsPerPage={filters.rowsPerPage}
                    page={filters.page}
                    onPageChange={(e, newPage) => setFilters((prevState) => ({
                        ...prevState,
                        page: newPage
                    }))}
                    onRowsPerPageChange={(e) => setFilters((prevState) => ({
                        ...prevState,
                        rowsPerPage: parseInt(e.target.value)
                    }))}
                />
            </Card>
        </Page>
    );
};

export default DashboardPage;