import {Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography} from "@mui/material";
import Page from "src/components/Page";
import {useGetAllCoursesQuery} from "src/services/courseApiService";
import {filter, sortBy} from "lodash";

const CoursesPage = () => {
    const {data: courses} = useGetAllCoursesQuery();

    return (
        <Page title={'Courses'} sx={{display: 'flex', flexGrow: 1, mt: 10}}>
            <Container>
                <Grid container spacing={1}>
                    {
                        sortBy(filter(courses, 'isPublished'), 'position')
                            .map((course, index) => (
                                <Grid item xs={8} sm={6} md={4} key={index}>
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                image={course.cardImageUrl}
                                                alt="cardImageUrl"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {course.name}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))
                    }
                </Grid>
            </Container>
        </Page>
    );
};

export default CoursesPage;