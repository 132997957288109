import {isRejectedWithValue, Middleware, MiddlewareAPI,} from '@reduxjs/toolkit';
import {toast} from "react-toastify";


export const rtkQueryErrorMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            toast.error('Eroare, probabil trebuie sa setezi tokenul din nou', {
                position: "bottom-center",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
        return next(action)
    }