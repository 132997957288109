import React from 'react';
import './App.css';
import 'simplebar-react/dist/simplebar.min.css';
import {Route, Routes} from "react-router-dom";
import LoginLayout from "src/layout/LoginLayout";
import LoginPage from "src/pages/LoginPage";
import StudentList from "src/pages/StudentList";
import DefaultLayout from "src/layout/DefaultLayout";
import RequireAuth from "src/components/RequireAuth";
import DashboardPage from "src/pages/DashboardPage";
import CoursesPage from "src/pages/CoursesPage";

const App = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginLayout/>}>
                <Route index element={<LoginPage/>}/>
            </Route>
            <Route path="/" element={<DefaultLayout/>}>
                <Route index element={<RequireAuth redirect><DashboardPage/></RequireAuth>}/>
                <Route path="/students" element={<RequireAuth redirect><StudentList/></RequireAuth>}/>
                <Route path="/courses" element={<RequireAuth redirect><CoursesPage/></RequireAuth>}/>
            </Route>
        </Routes>
    );
};

export default App;
