import Page from "src/components/Page";
import {
    Box,
    Container,
    Divider,
    LinearProgress,
    Tab,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Tabs
} from "@mui/material";
import React, {useState} from "react";
import {TableHeadCustom} from "src/components/table";
import StudentTableRow from "src/pages/StudentTableRow";
import {useFindAllStudentsQuery, useGetStatsQuery} from "src/services/studentApiService";
import {StudentType} from "src/domain/StudentType";
import StudentTableToolbar from "src/pages/StudentTableToolbar";
import Label from "src/components/Label";
import Scrollbar from "src/components/Scrollbar";
import {useGetAllCoursesQuery} from "src/services/courseApiService";
import {filter, sortBy} from "lodash";


const TABLE_HEAD = [
    {},
    {id: 'fullName', label: 'Name', align: 'left'},
    {id: 'email', label: 'Email', align: 'left'},
    {id: 'createdAt', label: 'Creat', align: 'left'},
    {id: 'lastSignIn', label: 'Ultima Logare', align: 'left'},
    {id: 'amountSpent', label: 'Total', align: 'center'}
];

const StudentList = () => {

    const [state, setState] = useState<{ searchTerm: string, page: number, order: 'asc' | 'desc', orderBy: string, rowsPerPage: number, selectedCourse: string, userType: 'students' | 'users' }>({
        orderBy: 'createdAt',
        order: 'desc',
        rowsPerPage: 25,
        page: 0,
        searchTerm: '',
        selectedCourse: 'all',
        userType: 'students'

    });
    const {data, isFetching} = useFindAllStudentsQuery({
        page: state.page,
        size: state.rowsPerPage,
        sort: `${state.orderBy},${state.order}`,
        searchTerm: state.searchTerm,
        selectedCourseId: state.selectedCourse,
        paid: state.userType === 'students'
    });

    const {data: courses} = useGetAllCoursesQuery();

    const {data: stats} = useGetStatsQuery();


    return (
        <Page title={'Studenti'} sx={{mt: 10, display: 'flex', flexGrow: 1, justifyContent: 'center'}}>
            <Container maxWidth={'lg'}>
                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={state.userType}
                    onChange={(e, newValue) => setState((prevState) => ({...prevState, userType: newValue}))}
                    sx={{px: 2, bgcolor: 'background.neutral'}}
                >
                    <Tab
                        disableRipple
                        key={'students'}
                        value={'students'}
                        icon={<Label color={'success'}> {stats?.students || 0}</Label>}
                        label={'Studenti'}
                    />
                    <Tab
                        disableRipple
                        key={'users'}
                        value={'users'}
                        icon={<Label color={'info'}> {stats?.users || 0}</Label>}
                        label={'Utilizatori'}
                    />
                </Tabs>
                <Divider/>
                <StudentTableToolbar
                    courses={sortBy(filter([{
                        id: 'all',
                        name: 'Toate cursurile'
                    }, ...(courses || [])], 'isPublished'), 'position')}
                    selectedCourse={state.selectedCourse}
                    onSelectCourse={(value) => setState((prevState) => ({...prevState, selectedCourse: value}))}
                    filterName={state.searchTerm}
                    onFilterName={(value) => setState((prevState) => ({...prevState, searchTerm: value, page: 0}))}
                />
                {
                    <Box sx={{width: '100%', height: '5px'}}>
                        {isFetching && (<LinearProgress/>)}
                    </Box>
                }
                <Scrollbar>
                    <TableContainer sx={{minWidth: 850, position: 'relative'}}>
                        <Table size={'small'} stickyHeader>
                            <TableHeadCustom
                                order={state.order}
                                orderBy={state.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={data?.numberOfElements || 0}
                                onSort={(orderBy) => setState((prevState) => ({...prevState, orderBy}))}
                            />
                            <TableBody>
                                {data?.content.map((row: StudentType) => (
                                    <StudentTableRow key={row.id} row={row}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
                <Box sx={{position: 'relative'}}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={data?.totalElements || 0}
                        rowsPerPage={state.rowsPerPage}
                        page={state.page}
                        onPageChange={(e, newPage) => setState((prevState) => ({
                            ...prevState,
                            page: newPage
                        }))}
                        onRowsPerPageChange={(e) => setState((prevState) => ({
                            ...prevState,
                            rowsPerPage: parseInt(e.target.value)
                        }))}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default StudentList;