import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from "src/store/store";
import {logout} from "src/store/authSlice";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

if (!BASE_URL) {
    throw new Error('BASE_URL env var is missing');
}


const baseFetchQuery = fetchBaseQuery({
    baseUrl: `${BASE_URL}/api`,
    prepareHeaders: (headers, {getState}) => {
        const token = (getState() as RootState).auth.token
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },

});

// @ts-ignore
const baseQueryWithInterceptor: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    const result = await baseFetchQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        api.dispatch(logout());

    } else {
        return result;
    }
}

export const baseApiService = createApi({
    baseQuery: baseQueryWithInterceptor,
    tagTypes: ['Students', 'Courses', 'Enrolments'],
    endpoints: () => ({}),
})